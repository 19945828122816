import React from "react";
import { Link } from "react-horizontal-router-dom";
import { useSelector } from "react-redux";

import logoWhite from "../images/prokure_logo_white_with_text.png";

const Footer = () => {
  const session = useSelector(state => state.auth.session);
  const logo = session?.options?.logo ?? logoWhite;
  const applicationId = session.applicationId;
  return (
    <div className="footer-wrapper bg-theme-gradient-108">
      <div className="footer">
        <div className="footer-header">
          <div className="logo">
            <Link to="/">
              <img src={logo} />
            </Link>
          </div>
          <div className="header-text">
            <div style={{ fontSize: 20 }}>
              Start growing your business today
            </div>
          </div>
        </div>
        <div className="footer-body">
          <div className="footer-body-left">
            {session && session.isBase && (
              <div>© 2020 - 2021 Mahamela Technologies Pvt Ltd</div>
            )}
            <div>
              <a
                href={`https://play.google.com/store/apps/details?id=${applicationId}`}>
                <img
                  style={{ height: 72 }}
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
